// A cover block is a full size cover with a fixed background for smaller screens.

@include td-box-height-modifiers(".td-cover-block");

.td-cover-logo {
  margin-right: 0.5em;
}

.td-cover-block {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: {
    repeat: no-repeat;
    position: 50% 0;
    size: cover;
  }

  & > .byline {
    @extend .small;
    position: absolute;
    bottom: 2px;
    right: 4px;
  }
}
