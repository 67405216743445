// The outer page container i.e. common styles for any page.
.td-outer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// The outer page container for the default base template.
.td-default {
  main {
    > section:first-of-type {
      @include media-breakpoint-up(md) {
        padding-top: 8rem;
      }
    }

    section {
      @extend .td-block-padding;
    }
  }
}

.td-main {
  flex-grow: 1;
}

.td-404 main,
.td-main main {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  @include media-breakpoint-up(md) {
    padding-top: 5.5rem;
  }
}
