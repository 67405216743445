.card,.td-content .highlight {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    
   
}

.card>hr,.td-content .highlight>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group,.td-content .highlight>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child,.td-content .highlight>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card>.list-group:last-child,.td-content .highlight>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card>.card-header+.list-group,.td-content .highlight>.card-header+.list-group,.card>.list-group+.card-footer,.td-content .highlight>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    flex: auto;
    min-height: 1px;
    padding: 1.25rem
}

.card-title {
    margin-bottom: .75rem
}

.card-subtitle {
    margin-top: -.375rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 1.25rem
}

.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
   
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px)calc(.25rem - 1px)0 0
}

.card-footer {
    padding: .75rem 1.25rem;
    background-color: rgba(0,0,0,3%);
    border-top: 1px solid rgba(0,0,0,.125)
}

.card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px)calc(.25rem - 1px)
}

.card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -.625rem;
    margin-left: -.625rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    border-radius: calc(.25rem - 1px)
}

.card-img,.card-img-top,.card-img-bottom {
    flex-shrink: 0;
    width: 100%
}

.card-img,.card-img-top {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card-img,.card-img-bottom {
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card-deck .card,.card-deck .td-content .highlight,.td-content .card-deck .highlight {
    margin-bottom: 15px
}

@media(min-width: 576px) {
    .card-deck {
        display:flex;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px
    }

    .card-deck .card,.card-deck .td-content .highlight,.td-content .card-deck .highlight {
        flex: 1 0;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px
    }
}

.card-group>.card,.td-content .card-group>.highlight {
    margin-bottom: 15px
}

@media(min-width: 576px) {
    .card-group {
        display:flex;
        flex-flow: row wrap
    }

    .card-group>.card,.td-content .card-group>.highlight {
        flex: 1 0;
        margin-bottom: 0
    }

    .card-group>.card+.card,.td-content .card-group>.highlight+.card,.td-content .card-group>.card+.highlight,.td-content .card-group>.highlight+.highlight {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child),.td-content .card-group>.highlight:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-img-top,.td-content .card-group>.highlight:not(:last-child) .card-img-top,.card-group>.card:not(:last-child) .card-header,.td-content .card-group>.highlight:not(:last-child) .card-header {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-img-bottom,.td-content .card-group>.highlight:not(:last-child) .card-img-bottom,.card-group>.card:not(:last-child) .card-footer,.td-content .card-group>.highlight:not(:last-child) .card-footer {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child),.td-content .card-group>.highlight:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-img-top,.td-content .card-group>.highlight:not(:first-child) .card-img-top,.card-group>.card:not(:first-child) .card-header,.td-content .card-group>.highlight:not(:first-child) .card-header {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-img-bottom,.td-content .card-group>.highlight:not(:first-child) .card-img-bottom,.card-group>.card:not(:first-child) .card-footer,.td-content .card-group>.highlight:not(:first-child) .card-footer {
        border-bottom-left-radius: 0
    }
}

.card-columns .card,.card-columns .td-content .highlight,.td-content .card-columns .highlight {
    margin-bottom: .75rem
}

@media(min-width: 576px) {
    .card-columns {
        -moz-column-count:3;
        column-count: 3;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1
    }

    .card-columns .card,.card-columns .td-content .highlight,.td-content .card-columns .highlight {
        display: inline-block;
        width: 100%
    }
}
.messi{
    color: aquamarine;
}

.td-page-meta--child { display: none !important; }
.td-page-meta--view { display: none !important; }
.td-page-meta--edit { display: none !important; }
.td-page-meta--issue { display: none !important; }
.td-page-meta--project-issue { display: none !important; }


.cust-header-section {
    padding-top: 0!important;
    padding-bottom: 20px!important;
    background: #e6edf7 url(https://nirmatastage.wpengine.com/wp-content/uploads/2018/10/legos-bg@2x.png?id=5588)!important;
    background-position: 50%!important;
    background-repeat: no-repeat!important;
    background-size: contain!important
}

.secondbackground {
    background: url(/images/featured-background2.jpg)!important;
    background-position: 50%!important;
    background-repeat: no-repeat!important;
    background-size: cover!important
}

.bannerfront {
    overflow: visible;
    color: white;
}

.bannerfront p {
    font-size: 20px;
    opacity: .8
}

.bannerfront  {
    color: white
}

.sectionfront {
    padding-top: 80px;
    padding-bottom: 80px;
}

@media(max-width: 768px) {
    .sectionfront {
        padding-top:60px
    }
    .cust-header-section{
        margin-top: 0;
    }
}

.section-smfront {
    padding-top: 60px;
    padding-bottom: 60px
}

@media(max-width: 768px) {
    .section-smfront {
        padding-top:40px
    }
}

.section-titlefront {
    margin-bottom: 40px
}

.bg-cover {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat
}


.containerfront {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .containerfront {
        max-width:540px
    }
}

@media (min-width: 768px) {
    .containerfront {
        max-width:720px
    }
}

@media (min-width: 992px) {
    .containerfront {
        max-width:960px
    }
}

@media (min-width: 1200px) {
    .containerfront {
        max-width:1140px
    }
}

.text-blueN {
    color: #2e5596!important
}

.text-blackN {
    color: #000000!important
}

.td-footer{
    height: 100px;
    min-height: 100px;
}

.productcards {
    max-width: 920px;
}

.sectionfront {
    padding-bottom: 5px;
}

.sectionfront.secondbackground.row {
    padding-top: 45px;
}

.td-box--dark, .td-footer {
    color: #fff;
    background-color: #1E345D;
}

.h1heading {
    // font-family: var(--font-family),sans-serif;
    font-weight: 500;
    line-height: 1.2;
    font-size: 45px;
}

.td-navbar {
    min-height: 4rem;
}
