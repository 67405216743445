// Mixins

@mixin link-decoration($base: none, $focus_or_hover: initial) {
  a {
    text-decoration: $base;

    &:focus,
    &:hover {
      text-decoration: $focus_or_hover;
    }
  }

  .btn-link {
    text-decoration: $base;
  }
}

@mixin link-variant($parent, $color, $hover-color, $underline: false) {
  #{$parent} {
    color: $color;

    &:hover {
      color: $hover-color;
    }

    @if $underline {
      text-decoration: underline;
    }
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

// placeholder allows styling of the placeholder used in search input etc.
@mixin placeholder {
  @include optional-at-root("::-webkit-input-placeholder") {
    @content;
  }

  @include optional-at-root(":-moz-placeholder") {
    @content;
  }

  @include optional-at-root("::-moz-placeholder") {
    @content;
  }

  @include optional-at-root(":-ms-input-placeholder") {
    @content;
  }
}
