.td-blog {
  .td-rss-button {
    @extend .btn;
    @extend .btn-info;
    @extend .btn-lg;

    border-radius: 2rem;
    float: right;

    display: none;
    @extend .d-lg-block;
  }

  &-posts-list {
    @extend .list-unstyled;
    margin-top: map-get($spacers, 4) !important;

    &__item {
      display: flex;
      align-items: flex-start;
      margin-bottom: map-get($spacers, 4) !important;

      &__body {
        flex: 1;
      }
    }

  }
}