//
// Style Markdown content
//

.td-content {
  order: 1;

  p,
  li,
  td {
    font-weight: $font-weight-body-text;
  }

  > h1 {
    font-weight: $font-weight-bold;
    margin-bottom: 1rem;
  }

  > h2 {
    margin-bottom: 1rem;
  }

  > h2:not(:first-child) {
    margin-top: 3rem;
  }

  > h2 + h3 {
    margin-top: 1rem;
  }

  > h3,
  > h4,
  > h5,
  > h6 {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  img {
    @extend .img-fluid;
  }

  table {
    @extend .td-table;
  }

  blockquote {
    padding: 0 0 0 1rem;
    margin-bottom: $spacer;
    color: var(--bs-secondary-color);
    border-left: 6px solid var(--bs-primary);
  }

  ul li,
  ol li {
    margin-bottom: 0.25rem;
  }

  strong {
    font-weight: $font-weight-bold;
  }

  .footnotes,
  > .alert,
  > .highlight,
  > .lead,
  > .td-table,
  > blockquote,
  > dl dd,
  > h1,
  > h2,
  > ol,
  > p,
  > pre,
  > ul {
    @extend .td-max-width-on-larger-screens;
  }

  .alert:not(:first-child) {
    margin-top: 2 * $spacer;
    margin-bottom: 2 * $spacer;
  }

  .lead {
    margin-bottom: 1.5rem;
  }
}

.td-title {
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  @include media-breakpoint-up(sm) {
    font-size: 3rem;
  }
}

.td-heading-self-link {
  &:before {
    content: '#';
  }

  font-size: 90%;
  padding-left: 0.25em;
  text-decoration: none;
  visibility: hidden;

  // Always visible on touch devices and small screens
  @media (hover: none) and (pointer: coarse),
  (max-width: map-get($grid-breakpoints, sm)) {
    visibility: visible;
  }

}

@for $i from 1 through 6 {
  h#{$i}:hover > .td-heading-self-link {
    visibility: visible;
  }
}
