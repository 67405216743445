// Only constrain max-width for top-level tabbed panes not, e.g., those in lists.
.td-content > .tab-content .tab-pane {
  @extend .td-max-width-on-larger-screens;
}

.tab-content {
  .tab-pane {
    .highlight {
      margin: 0;
      border: none;
      max-width: 100%;
    }
    margin-top: 0rem;
    margin-bottom: 1.5rem;
    border-left: $nav-tabs-border-width solid $nav-tabs-border-color;
    border-right: $nav-tabs-border-width solid $nav-tabs-border-color;
    border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
  }
}

.tab-body {
  font-weight: $font-weight-medium;
  background: var(--td-pre-bg);
  color: var(--bs-body-color);
  border-radius: 0;
  padding: 1.5rem;

  > :last-child {
    margin-bottom: 0;
  }

  > .highlight:only-child {
    margin: -1.5rem;
    max-width: calc(100% + 3rem);
  }

  @each $color, $value in $theme-colors {
    &-#{$color} {
      border-style: solid;
      border-color: $value;
    }
  }
}
