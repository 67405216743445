.pageinfo {
  font-weight: $font-weight-medium;
  background: var(--bs-alert-bg);
  color: inherit;
  margin: 2rem auto;
  padding: 1.5rem;
  padding-bottom: 0.5rem;

  @each $color, $value in $theme-colors {
    &-#{$color} {
      @extend .alert-#{$color};
      border-width: 0;
    }
  }
}

.td-page-meta {
  &__lastmod {
    @extend .text-body-secondary;
    @extend .border-top;
    margin-top: map-get($spacers, 5) !important;
    padding-top: map-get($spacers, 3) !important;
  }
}
