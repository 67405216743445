//
// Right side toc
//
.td-sidebar-toc {
  @include link-decoration;

  border-left: 1px solid var(--bs-border-color);

  @supports (position: sticky) {
    position: sticky;
    top: 4rem;
    height: calc(100vh - 4rem);
    overflow-y: auto;
  }

  order: 2;
  padding-top: 0.75rem;
  padding-bottom: 1.5rem;
  vertical-align: top;

  .td-page-meta a {
    display: block;
    font-weight: $font-weight-medium;
  }
}

.td-toc {
  a {
    display: block;
    font-weight: $font-weight-light;
    padding-bottom: 0.25rem;
  }

  li {
    list-style: none;
    display: block;
  }

  li li {
    margin-left: 0.5rem;
  }

  #TableOfContents {
    // Hugo's ToC is a mouthful, this can be used to style the top level h2 entries.
    > ul > li > ul > li > a {
    }

    a {
      color: var(--bs-secondary-color);

      &:focus,
      &:hover {
        color: initial;
      }
    }
  }

  ul {
    padding-left: 0;
  }
}
