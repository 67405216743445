// Forward declarations of variables used by _variables.scss and
// _variables_project.scss.
//
// TODO(@chalin): autogenerate the content of this file

// Bootstrap SASS variables:

$white:    #fff !default;
$gray-300: #dee2e6 !default;
$gray-600: #6c757d !default;
$black:    #000 !default;

$blue:    #0d6efd !default;

$blue-500: $blue !default;

$font-size-base: 1rem !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$spacer: 1rem !default;
