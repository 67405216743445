// Code formatting.

@include color-mode(dark, true) {
  --td-pre-bg: #{adjust-color($gray-900, $lightness: -2.5%)};
}

.td-content {
  // Highlighted code.
  .highlight {
    margin: 2rem 0;
    padding: 0;
    position: relative;

    .click-to-copy {
      display: block;
      text-align: right;
    }

    pre {
      margin: 0;
      padding: 1rem;
      border-radius: inherit;

      // Default click-to-copy button

      button.td-click-to-copy {
        position: absolute;
        color: var(--bs-tertiary-color);
        border-width: 0;
        background-color: transparent;
        background-image: none;
        --bs-btn-box-shadow: 0;
        padding: var(--bs-btn-padding-y) calc(var(--bs-btn-padding-x) / 2);
        right: 4px;
        top: 2px;

        &:hover {
          color: var(--bs-secondary-color);
          background-color: var(--bs-dark-bg-subtle);
        }
        &:active {
          color: var(--bs-secondary-color);
          background-color: var(--bs-dark-bg-subtle);
          transform: translateY(2px);
        }
      }
    }
  }

  // Inline code
  p code,
  li > code,
  table code {
    color: inherit;
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    word-break: normal;
    background-color: var(--td-pre-bg);
    border-radius: $border-radius;

    br {
      display: none;
    }
  }

  // Code blocks
  pre {
    word-wrap: normal;
    background-color: var(--td-pre-bg);
    border: solid var(--bs-border-color);
    border-width: 1px;
    padding: $spacer;

    > code {
      background-color: inherit !important;
      padding: 0;
      margin: 0;
      font-size: 100%;
      word-break: normal;
      white-space: pre;
      border: 0;
    }
  }

  pre.mermaid {
    background-color: inherit;
    font-size: 0;
    padding: 0;
  }
}
