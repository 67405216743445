// Taxonomies - e.g. Tags, Categories, ...

.taxonomy-terms-article {
  width: 100%;
  clear: both;
  font-size: 0.8rem;

  .taxonomy-title {
    display: inline;
    font-size: 1.25em;
    height: 1em;
    line-height: 1em;
    margin-right: 0.5em;
    padding: 0;
  }
}

.taxonomy-terms-cloud {
  width: 100%;
  clear: both;
  font-size: 0.8rem;

  .taxonomy-title {
    display: inline-block;
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    color: var(--bs-primary-text-emphasis);
    border-bottom: 1px solid var(--bs-tertiary-color);
    margin-bottom: 1em;
    padding-bottom: 0.375rem;
    margin-top: 1em;
  }
}

.taxonomy-terms-page {
  max-width: 800px;
  margin: auto;

  h1 {
    margin-bottom: 1em;
  }

  .taxonomy-terms-cloud {
    font-size: 1em;

    li {
      display: block;
    }
  }

  .taxo-text-tags {
    li + li::before {
      content: none;
    }
  }

  .taxo-fruits {
    .taxonomy-count,
    .taxonomy-label {
      display: inherit;
      font-size: 1rem;
      margin: 0;
      padding: 0;
      padding-right: 0.5em;
    }

    .taxonomy-count::before {
      content: "(";
    }
    .taxonomy-count::after {
      content: ")";
    }
  }
}

.taxonomy-terms {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  display: inline;

  li {
    // https://stackoverflow.com/questions/3247358/how-do-i-wrap-text-with-no-whitespace-inside-a-td
    display: inline;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
}

.taxonomy-count {
  font-size: 0.8em;
  line-height: 1.25em;
  display: inline-block;
  padding-left: 0.6em;
  padding-right: 0.6em;
  margin-left: 0.6em;
  text-align: center;
  border-radius: 1em;
  background-color: var(--bs-body-bg);
}

.taxonomy-term {
  background: var(--bs-secondary-bg);
  border-width: 0;
  border-radius: 0 3px 3px 0;
  color: var(--bs-body-color);
  display: inline-block;
  font-size: 1em;
  line-height: 1.5em;
  min-height: 1.5em;
  max-width: 100%;
  padding: 0 0.5em 0 1em;
  position: relative;
  margin: 0 0.5em 0.2em 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0.8em 100%, 0 50%, 0.8em 0);
  clip-path: polygon(100% 0, 100% 100%, 0.8em 100%, 0 50%, 0.8em 0);

  &:hover {
    background-color: var(--bs-primary-bg-subtle);
    color: var(--bs-body-color-dark);

    .taxonomy-count {
      color: var(--bs-body-color-dark);
    }
  }

  &:hover::before {
    background: $primary;
  }
}

// Example for simple tags layout
.taxo-text-tags {
  .taxonomy-term {
    background: none;
    border-width: 0;
    border-radius: 0;
    color: $gray-600;
    font-size: 1em;
    line-height: 1.5em;
    min-height: 1.5em;
    max-width: 100%;
    padding: 0;
    position: relative;
    margin: 0;
    text-decoration: none;
    -webkit-clip-path: none;
    clip-path: none;

    &:hover {
      background: none;
      color: $link-color;

      .taxonomy-count {
        color: $dark !important;
      }
    }

    &:hover::before {
      background: none;
    }
  }

  li + li::before {
    content: "|";
    color: $gray-600;
    margin-right: 0.2em;
  }

  .taxonomy-count {
    font-size: 1em;
    line-height: 1.25em;
    display: inline-block;
    padding: 0;
    margin: 0;
    text-align: center;
    border-radius: 0;
    background: none;
    vertical-align: super;
    font-size: 0.75em;
  }

  .taxonomy-term:hover .taxonomy-count {
    color: $link-color !important;
  }
}

// Example for icon tags
.taxo-fruits {
  .taxonomy-term[data-taxonomy-term]::before {
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: $font-awesome-font-name;
    // font-weight: 900;
    padding-right: 0.5em;
    font-size: 2em;
    min-width: 1.5em;
    display: inline-block;
  }

  .taxonomy-term[data-taxonomy-term="apple"]::before {
    content: "\f5d1";
    color: red;
  }

  .taxonomy-term[data-taxonomy-term="carrot"]::before {
    content: "\f787";
    color: orange;
  }

  .taxonomy-term[data-taxonomy-term="lemon"]::before {
    content: "\f094";
    color: limegreen;
  }

  .taxonomy-term[data-taxonomy-term="pepper"]::before {
    content: "\f816";
    color: darkred;
  }

  .taxonomy-term {
    background: none;
    border-width: 0;
    border-radius: 0;
    color: $gray-600;
    font-size: 1em;
    line-height: 2.5em;
    max-width: 100%;
    padding: 0;
    position: relative;
    margin: 0;
    text-decoration: none;
    -webkit-clip-path: none;
    clip-path: none;

    &:hover {
      background: none;
      color: $link-color;

      .taxonomy-count {
        color: $dark !important;
      }
    }

    &:hover::before {
      background: none;
      text-shadow: 0 0 3px $gray-900;
    }
  }

  .taxonomy-count,
  .taxonomy-label {
    display: none;
  }

  &.taxonomy-terms-article {
    margin-bottom: 1rem;

    .taxonomy-title {
      display: none;
    }
  }
}

.taxonomy-taxonomy-page {
  max-width: 800px;
  margin: auto;

  h1 {
    margin-bottom: 1em;
  }
}

.article-meta {
  margin-bottom: 1.5rem;
}

.article-teaser.article-type-docs h3 a:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: $font-awesome-font-name;
  content: "\f02d";
  padding-right: 0.5em;
}

.article-teaser.article-type-blog h3 a:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: $font-awesome-font-name;
  content: "\f781";
  padding-right: 0.5em;
}

.all-taxonomy-terms {
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.5rem;

  &:before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: $font-awesome-font-name;
    content: "\f122";
    padding-right: 0.5em;
  }
}

.article-teaser {
  &.card {
    padding: 1em;
    margin-bottom: 1.5em;
  }

  .breadcrumb {
    margin-bottom: 0em;
    font-size: 0.85rem;
  }

  .article-meta {
    margin-bottom: 0em;
  }
}
