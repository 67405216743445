// Bootstrap options

$enable-gradients: true !default;
$enable-shadows: true !default;

// Theme colors

// TODO: consider moving all of these theme-color definitions into the User
// Guide SCSS, or declare theme here, but namespaced:

$primary: #30638e !default;
$secondary: #ffa630 !default;
$success: #3772ff !default;
$info: #c0e0de !default;
$warning: #ed6a5a !default;
$danger: #ed6a5a !default;
$light: #d3f3ee !default; // TODO: consider using BS value
$dark: #403f4c !default;  // TODO: consider using BS value

$code-color: shade-color($secondary, 40%) !default;

:root,
[data-bs-theme="light"] {
  --td-pre-bg: var(--bs-tertiary-bg);
}

// UI element colors

// Background colors for the sections on home page etc. It is a paint by number
// system, starting at 0, where the number is taken from the shortcode's ordinal
// if not provided by the user. These colors are all part of the theme palette,
// but the mix is fairly random to create variation. This can be overridden by
// the project if needed.
$td-box-colors: $dark, $primary, $secondary, $info, $white, $gray-600, $success,
  $warning, $dark, $danger, $primary, $secondary, $info !default;

$link-color: $blue-500 !default;
$link-shade-percentage: 30% !default;

// Fonts

$td-enable-google-fonts: true !default;

$google_font_name: "Open Sans" !default;
$google_font_family: "Open+Sans:300,300i,400,400i,700,700i" !default;
$web-font-path: "https://fonts.googleapis.com/css?family=#{$google_font_family}&display=swap";
$font-awesome-font-name: "Font Awesome 6 Free" !default;

$td-fonts-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";

@if $td-enable-google-fonts {
  $td-fonts-serif: join("#{$google_font_name}", $td-fonts-serif);
}

$font-family-sans-serif: $td-fonts-serif !default; // TODO: consider moving into UG SCSS or namespace the var (td-)

$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace !default; // TODO: consider moving into UG SCSS or namespace the var (td-)
$font-family-base: $font-family-sans-serif !default; // TODO: consider moving into UG SCSS or namespace the var (td-)

// Font weights

$font-weight-medium: 500 !default; // TODO: move into var forward file after upgrading BS to v5.3+

$font-weight-body-text: $font-weight-normal !default;

// Heading sizes

$h3-font-size: $font-size-base * 1.5 !default;  // TODO: consider using BS default (* 1.75), or moving into UG SCSS
$h4-font-size: $font-size-base * 1.35 !default; // TODO: consider using BS default (* 1.5), or moving into UG SCSS
$h5-font-size: $font-size-base * 1.15 !default; // TODO: consider using BS default (* 1.25), or moving into UG SCSS

// Space

$td-block-space-top-base: 4 * $spacer !default;
$td-block-space-bottom-base: 4 * $spacer !default;

// Pagination

$pagination-color: $gray-600 !default; // TODO: consider using BS default
$pagination-disabled-color: $gray-300 !default; // TODO: consider using BS default

// Footer

$list-inline-padding: $spacer;
