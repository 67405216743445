// Style alert boxes.

.alert {
  font-weight: $font-weight-medium;
  color: inherit;
  border-radius: 0;

  @each $color, $value in $theme-colors {
    &-#{$color} {
      & .alert-heading {
        color: $value;
      }

      border-style: solid;
      border-color: $value;
      border-width: 0 0 0 4px;
    }
  }
}
