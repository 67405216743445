//
// Left side navigation
//
.td-sidebar-nav {
  $_max-height: calc(100vh - 8.5rem);

  padding-right: 0.5rem;
  margin-right: -15px;
  margin-left: -15px;

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      max-height: $_max-height;
      overflow-y: auto;
    }
  }

  // Adjust height and padding when sidebar_search_disable is true, but only for
  // >= `lg` views, because on tablet (`md`) and mobile (<= `sm`), the search
  // box is displayed regardless of the value of sidebar_search_disable:
  &.td-sidebar-nav--search-disabled {
    @include media-breakpoint-up(lg) {
      // There's no search box so add top padding
      // and adjust max-height:
      padding-top: 1rem;

      @supports (position: sticky) {
        max-height: calc(#{$_max-height} + 4.5rem);
      }
    }
  }

  @include media-breakpoint-up(md) {
    display: block !important;
  }

  &__section {
    li {
      list-style: none;
    }

    &.ul-0, ul {
      padding: 0;
      margin: 0;
    }

    @include media-breakpoint-up(md) {
      & .ul-1 ul {
        padding-left: 1.5em;
      }
    }

    padding-left: 0;
  }

  &__section-title {
    display: block;
    font-weight: $font-weight-medium;

    .active {
      font-weight: $font-weight-bold;
    }

    a {
      color: var(--bs-secondary-color);
    }
  }

  .td-sidebar-link {
    display: block;
    padding-bottom: 0.375rem;

    &__page {
      color: var(--bs-body-color);
      font-weight: $font-weight-light;
    }
  }

  a {
    &:focus,
    &:hover {
      color: var(--bs-link-color);
    }

    &.active {
      font-weight: $font-weight-bold;
    }
  }

  .dropdown {
    a {
      color: var(--bs-tertiary-color);
    }

    .nav-link {
      padding: 0 0 1rem;
    }
  }

  & > .td-sidebar-nav__section {
    padding-left: 1.5rem;
  }

  li i {
    // Layout of icons
    padding-right: 0.5em;
    &:before {
      display: inline-block;
      text-align: center;
      min-width: 1em;
    }
  }

  .td-sidebar-link.tree-root {
    font-weight: $font-weight-bold;
    border-bottom: 1px solid var(--bs-tertiary-color);
    margin-bottom: 1rem;
  }
}

.td-sidebar {
  @include link-decoration;

  @include media-breakpoint-up(md) {
    padding-top: 4rem;
    background-color: var(--bs-body-tertiary-bg);
    padding-right: 1rem;
    border-right: 1px solid var(--bs-border-color);
  }

  padding-bottom: 1rem;

  &__toggle {
    line-height: 1;
    color: var(--bs-body-color);
    margin: 1rem;
  }

  &__search {
    padding: 1rem 0;
  }

  &__inner {
    order: 0;

    @include media-breakpoint-up(md) {
      @supports (position: sticky) {
        position: sticky;
        top: 4rem;
        z-index: 10;
        height: calc(100vh - 5rem);
      }
    }

    @include media-breakpoint-up(xl) {
      flex: 0 1 320px;
    }

    .td-search-box {
      width: 100%;
    }
  }

  #content-desktop {
    display: block;
  }
  #content-mobile {
    display: none;
  }

  @include media-breakpoint-down(lg) {
    #content-desktop {
      display: none;
    }
    #content-mobile {
      display: block;
    }
  }
}
